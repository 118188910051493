import React from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo"; 
import Navbar from "../components/_App/Navbar";
import CloudApplicationsBanner from '../components/MachineLearning/CloudApplicationsBanner';
import WhatWeOffer from '../components/MachineLearning/WhatWeOffer';
import AboutUsContent from '../components/MachineLearning/AboutUsContent';
import OurServices from '../components/ITStartupTwo/OurServices';
import FunFactsArea from '../components/Common/FunFactsArea';
import Newsletter from '../components/Common/Newsletter';
import Footer from "../components/_App/Footer";

const CloudApplications = () => (
    <Layout>
        <SEO title="Cloud Applications" /> 
        <Navbar />
        <CloudApplicationsBanner />
        <WhatWeOffer />
        <AboutUsContent />
        <OurServices />
        <FunFactsArea />
        <Newsletter />
        <Footer />
    </Layout>
)

export default CloudApplications;
